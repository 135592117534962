
@font-face {
  font-family: 'Horas';
  src: local('Horas'), url(./fonts/Horas/Horas-Black.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Work Sans';
  src: local('Work Sans'), url(./fonts/Work\ Sans/WorkSans-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  scroll-behavior: smooth;
}

body
{
  margin: 0;
  padding: 0;
  font-family: 'Work Sans';
}
.App {
  background-image: url(./bg/dark.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  #backgroundBlur
  {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    background-color: #00000000;
    animation: blur 0.3s forwards;
  }
  .sideNav
  {
    z-index: 10;
    width: 400px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    animation: slideIn 0.3s forwards;
    .logoWrap
    {
      width: 100%;
      display: flex;
      gap: 15px;
      align-items: center;
      img
      {
        width: 70%;
      }
    }
    .tab
    {
      width: 100%;
      padding: 15px;
      border-radius: 10px;
      background: #EFA7D7;;
      border: 0;
      outline: 0;
      text-align: left;
      margin-top: 50px;
      font-weight: bold;
      font-size: 1.1rem;
    }
    ul
    {
      padding-left: 20px;
      margin: 10px 0;
      font-size: 1.2rem;
      a
      {
        text-decoration: none;
        color: #000;
      }
      li
      {
        background: rgb(255, 243, 252);
        padding: 10px;
        border-radius: 5px;
        margin-top: 5px;
      }
    }
  }
  .App-header
  {
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    position: sticky;
    background:#2f2e3eaf;
    backdrop-filter: blur(5px);
    #logo
    {
      width: 350px;
    }
  }
  .titleWrap
  {
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .heading1
    {
      font-size: 85px;
      font-weight: bold;
      text-align: center;
      width: 1200px;
      color: #fff;
      font-family: Horas;
    }
  }
  .videoWrap
  {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    .video1
    {
      border-radius: 10px;
      width: 80vw;
      height: 45vw;
      background: transparent;
    }
  }
  #coverage
  {
    display: flex;
    flex-direction: column;
    padding: 200px 20vw;
    h3
    {
      text-align: center;
      margin: 0;
      color: #fff;
      font-size: 1.6rem;
      font-family: Horas;
    }
    h2
    {
      text-align: center;
      margin: 0;
      color: #fff;
      font-size: 60px;
      font-family: Horas;
    }
    p
    {
      color: #fff;
      text-align: center;
    }
    #app-list
    {
      display: flex;
      overflow-x: auto;
      justify-content: center;
      margin-top: 50px;
      gap: 15px;
      img
      {
        width: 100px;
      }
    }
  }

  #standoutFeatures, #coreFeatures
  {
    display: flex;
    flex-direction: column;
    padding: 50px 20vw;
    border-top: 3px solid #ffffff0c;
    hr
    {
      border: 0;
      height: 1px;
      width: 200px;
      margin: 50px auto;
      background: #fff;
    }
    h3
    {
      text-align: center;
      margin: 0;
      color: #fff;
      font-size: 1.6rem;
      font-family: Horas;
    }
    h2
    {
      text-align: center;
      margin: 0;
      color: #fff;
      font-size: 60px;
      font-family: Horas;
    }
    p
    {
      color: #fff;
      text-align: center;
    }
    .featureSection
    {
      display: flex;
      margin-top: 50px;
      height: 400px;
      border-radius: 10px;
      background: #ffffff10;
      box-sizing: border-box;
      padding: 50px;
      gap: 40px;
      &.hidden
      {
        display: none;
      }
      .featureContent
      {
        width: 50%;
        h3
        {
          text-align: left;
          margin: 0;
          color: #fff;
          font-size: 35px;
        }
        p
        {
          text-align: left;

          font-size: 20px;
        }
        .thumbnail
        {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          .tinyVideo
          {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: transparent;
          }
        }
      }
    }

    &#coreFeatures .featureSection
    {
      flex-direction: row-reverse;
    }

  }
}

@keyframes blur
{
  from
  {
    backdrop-filter: blur(0px);
    background-color: #00000000;
  }
  to
  {
    backdrop-filter: blur(5px);
    background-color: #00000039;
  }
}

@keyframes slideIn
{
  0%
  {
    left: -500px;
  }

  100%
  {
    left: 0px;
  }
}
@font-face {
  font-family: "Horas";
  src: local("Horas"), url(./fonts/Horas/Horas-Black.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "Work Sans";
  src: local("Work Sans"), url(./fonts/Work\ Sans/WorkSans-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans";
}

.App {
  background-image: url(./bg/dark.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}
.App #backgroundBlur {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0px);
  background-color: rgba(0, 0, 0, 0);
  animation: blur 0.3s forwards;
}
.App .sideNav {
  z-index: 10;
  width: 400px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  animation: slideIn 0.3s forwards;
}
.App .sideNav .logoWrap {
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
}
.App .sideNav .logoWrap img {
  width: 70%;
}
.App .sideNav .tab {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: #EFA7D7;
  border: 0;
  outline: 0;
  text-align: left;
  margin-top: 50px;
  font-weight: bold;
  font-size: 1.1rem;
}
.App .sideNav ul {
  padding-left: 20px;
  margin: 10px 0;
  font-size: 1.2rem;
}
.App .sideNav ul a {
  text-decoration: none;
  color: #000;
}
.App .sideNav ul li {
  background: rgb(255, 243, 252);
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}
.App .App-header {
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
  position: sticky;
  background: rgba(47, 46, 62, 0.6862745098);
  backdrop-filter: blur(5px);
}
.App .App-header #logo {
  width: 350px;
}
.App .titleWrap {
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App .titleWrap .heading1 {
  font-size: 85px;
  font-weight: bold;
  text-align: center;
  width: 1200px;
  color: #fff;
  font-family: Horas;
}
.App .videoWrap {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.App .videoWrap .video1 {
  border-radius: 10px;
  width: 80vw;
  height: 45vw;
  background: transparent;
}
.App #coverage {
  display: flex;
  flex-direction: column;
  padding: 200px 20vw;
}
.App #coverage h3 {
  text-align: center;
  margin: 0;
  color: #fff;
  font-size: 1.6rem;
  font-family: Horas;
}
.App #coverage h2 {
  text-align: center;
  margin: 0;
  color: #fff;
  font-size: 60px;
  font-family: Horas;
}
.App #coverage p {
  color: #fff;
  text-align: center;
}
.App #coverage #app-list {
  display: flex;
  overflow-x: auto;
  justify-content: center;
  margin-top: 50px;
  gap: 15px;
}
.App #coverage #app-list img {
  width: 100px;
}
.App #standoutFeatures, .App #coreFeatures {
  display: flex;
  flex-direction: column;
  padding: 50px 20vw;
  border-top: 3px solid rgba(255, 255, 255, 0.0470588235);
}
.App #standoutFeatures hr, .App #coreFeatures hr {
  border: 0;
  height: 1px;
  width: 200px;
  margin: 50px auto;
  background: #fff;
}
.App #standoutFeatures h3, .App #coreFeatures h3 {
  text-align: center;
  margin: 0;
  color: #fff;
  font-size: 1.6rem;
  font-family: Horas;
}
.App #standoutFeatures h2, .App #coreFeatures h2 {
  text-align: center;
  margin: 0;
  color: #fff;
  font-size: 60px;
  font-family: Horas;
}
.App #standoutFeatures p, .App #coreFeatures p {
  color: #fff;
  text-align: center;
}
.App #standoutFeatures .featureSection, .App #coreFeatures .featureSection {
  display: flex;
  margin-top: 50px;
  height: 400px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.062745098);
  box-sizing: border-box;
  padding: 50px;
  gap: 40px;
}
.App #standoutFeatures .featureSection.hidden, .App #coreFeatures .featureSection.hidden {
  display: none;
}
.App #standoutFeatures .featureSection .featureContent, .App #coreFeatures .featureSection .featureContent {
  width: 50%;
}
.App #standoutFeatures .featureSection .featureContent h3, .App #coreFeatures .featureSection .featureContent h3 {
  text-align: left;
  margin: 0;
  color: #fff;
  font-size: 35px;
}
.App #standoutFeatures .featureSection .featureContent p, .App #coreFeatures .featureSection .featureContent p {
  text-align: left;
  font-size: 20px;
}
.App #standoutFeatures .featureSection .featureContent .thumbnail, .App #coreFeatures .featureSection .featureContent .thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.App #standoutFeatures .featureSection .featureContent .thumbnail .tinyVideo, .App #coreFeatures .featureSection .featureContent .thumbnail .tinyVideo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent;
}
.App #standoutFeatures#coreFeatures .featureSection, .App #coreFeatures#coreFeatures .featureSection {
  flex-direction: row-reverse;
}

@keyframes blur {
  from {
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.2235294118);
  }
}
@keyframes slideIn {
  0% {
    left: -500px;
  }
  100% {
    left: 0px;
  }
}

